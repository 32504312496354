export const isNotNullOrEmpty = (text: string) => {
    return text !== null && text !== undefined && text.length > 0
}
const getAge = (dateString: string) => {
    const today = new Date();
    const birthDate = new Date(dateString);
    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }
    return age;
}
export const validate = (value: any, options?: { minLength: number, type: "text" | "email" | "birthday" }) => {
    if (options?.type === "text")
        return value !== null && value !== undefined && value.length >= (options?.minLength ?? 0)

    if (options?.type === "email")
        return value.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)

    if (options?.type === "birthday")
        return value !== null && value !== undefined && getAge(value) > 17;


    return value !== null && value !== undefined && value.length > 0
}

export const toDate = function (text: string): string | null {
    if (text === null || text === undefined || text.length < 1) return null

    try {
        const date = new Date(text.toString())

        return new Date(Date.UTC(
            date.getFullYear(),
            date.getMonth(),
            date.getDate()
        )).toISOString().split('T')[0]

    } catch (err) {
        return null
    }
}