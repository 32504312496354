import React from 'react';
import Button from "@mui/material/Button";
import {Error} from "@mui/icons-material";
import {FormattedMessage} from "react-intl";

const ErrorComponent = (options: { setHasError: any }) => {
    return (
        <div className="custom-card">
            <div className="d-flex flex-column justify-content-between align-items-center text-center my-auto">
                <Error className="error-icon mb-1rem"/>
                <div className="my-4">
                    <h1>
                        <FormattedMessage id={"error"} defaultMessage="???"/>
                    </h1>
                </div>
                <Button
                    variant="outlined"
                    onClick={() => {
                        options.setHasError(false)
                    }}>
                    <FormattedMessage id={"go.back"} defaultMessage="???"/>
                </Button>
            </div>

        </div>
    );
};

export default ErrorComponent;