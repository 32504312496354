import React, {useState} from 'react';
import {Box, Modal} from "@mui/material";
import {Close, Fullscreen} from "@mui/icons-material";
import {ICustomerInfo} from "../../models/ICustomerInfo";
import {FormattedMessage} from "react-intl";

const VerifyInfoSection = (options: { customerInfo: any, setCustomerInfo: any }) => {
    const [open, setOpen] = useState(false);
    const handleOpen = (url: any) => {
        setOpen(true)
        setSelectedImage(url)
    }
    const handleClose = () => setOpen(false);

    const [selectedImage, setSelectedImage] = useState()

    const style = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 5,
    };

    return (
        <div>
            <h2>
                <FormattedMessage id={"verify.info"} defaultMessage="???"/>
            </h2>
            <hr/>
            <div className="my-4">
                <div className="mb-4">
                    <p>
                        <strong><FormattedMessage id={"gender"} defaultMessage="???"/>: </strong>
                        {
                            options.customerInfo.gender === true ?
                                <FormattedMessage id={"gender.male"} defaultMessage="???"/> :
                                <FormattedMessage id={"gender.female"} defaultMessage="???"/>
                        }
                    </p>
                    <p>
                        <strong><FormattedMessage id={"name"} defaultMessage="???"/>: </strong>
                        {options.customerInfo.name}
                    </p>
                    <p>
                        <strong><FormattedMessage id={"surname"} defaultMessage="???"/>: </strong>
                        {options.customerInfo.surname}
                    </p>
                    <p>
                        <strong><FormattedMessage id={"birthday"} defaultMessage="???"/>: </strong>
                        {options.customerInfo.birthday}
                    </p>
                    <p>
                        <strong><FormattedMessage id={"email"} defaultMessage="???"/>: </strong>
                        {options.customerInfo.email}
                    </p>
                    <p>
                        <strong><FormattedMessage id={"phone.number"} defaultMessage="???"/>: </strong>
                        {options.customerInfo.phoneNumber}
                    </p>
                    <p>
                        <strong><FormattedMessage id={"zipcode"} defaultMessage="???"/>: </strong>
                        {options.customerInfo.zipCode}
                    </p>
                    <p>
                        <strong><FormattedMessage id={"country"} defaultMessage="???"/>: </strong>
                        {options.customerInfo.country}
                    </p>
                    <p>
                        <strong><FormattedMessage id={"state"} defaultMessage="???"/>: </strong>
                        {options.customerInfo.state}
                    </p>
                    <p>
                        <strong><FormattedMessage id={"city"} defaultMessage="???"/>: </strong>
                        {options.customerInfo.city}
                    </p>
                </div>
                <hr/>
                <div>
                    <h3 className="h4">
                        <FormattedMessage id={"uploaded.photos"} defaultMessage="???"/>
                    </h3>
                    <div className="row mt-4">
                        {options.customerInfo.photos.length > 0 &&
                            options.customerInfo.photos?.map((url: any, index: number) => {
                                return (
                                    <div className="col-4" key={index}>
                                        <div
                                            className="position-relative w-100"
                                            onClick={() => handleOpen(url)}>
                                            <Fullscreen
                                                className="fullscreen-icon"/>
                                            <img
                                                className="uploaded-img img-fluid"
                                                src={url}
                                                alt={url}/>
                                        </div>
                                    </div>
                                )
                            })}

                    </div>
                    <Modal
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box sx={style}>
                            <div className="close-button" onClick={handleClose}>
                                <Close/>
                            </div>
                            <img
                                className="modal-img img-fluid"
                                key={selectedImage}
                                src={selectedImage}
                                alt={selectedImage}/>
                        </Box>

                    </Modal>
                </div>
                <hr/>
                <div>
                    <input type="checkbox"
                           className="form-check-input me-2"
                           id="isAccepted"
                           checked={options.customerInfo.isPolicyChecked}
                           onChange={(event) => {
                               options.setCustomerInfo((pci: ICustomerInfo) => {
                                   const photosFormData = pci.photosFormData
                                   const clone = JSON.parse(JSON.stringify(pci))
                                   clone.isPolicyChecked = event.target.checked
                                   clone.photosFormData = photosFormData
                                   return clone
                               })
                           }}
                    />
                    <span>
                        <a href="https://melislekazan.com/policy"
                           target="_blank"
                           rel="noreferrer">
                            <FormattedMessage id={"privacy.policy"} defaultMessage="???"/>
                        </a>
                    </span>
                </div>
            </div>
        </div>
    );
};

export default VerifyInfoSection;