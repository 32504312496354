import React from 'react';
import TRFlag from "./icons/tr";
import DEFlag from "./icons/de";
import FRFlag from "./icons/fr";

const LanguageSwitcher = (options: { locale: string, onClick: any }) => {
    let icon
    switch (options.locale) {
        case "tr":
            icon = <TRFlag/>
            break
        case "de":
            icon = <DEFlag/>
            break
        case "fr":
            icon = <FRFlag/>
            break
        default:
            break
    }
    return (
        <span onClick={() => {
            options.onClick(options.locale)
            localStorage.setItem("locale", options.locale);
        }} className="mx-2" style={{cursor: "pointer"}}>
            <div style={{width: "2rem"}} className="d-inline-block">
            {icon}
            </div>
        </span>
    );
};

export default LanguageSwitcher;