import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import {IStep} from "../models/IStep";
import {FormattedMessage} from "react-intl";

export default function CustomStepper(options: {
    steps: IStep[],
    activeStep: any,
    isStepSkipped: any,
    handleBack: any,
    handleNext: any,
    handleSubmit: any,
}) {
    return (
        <>
            {options.activeStep !== options.steps.length - 1 &&
                <Stepper activeStep={options.activeStep}
                         alternativeLabel={true}
                         style={{marginBottom: "1rem"}}>
                    {options.steps.map((step) => {
                        const stepProps: { completed?: boolean } = {};
                        const labelProps: {
                            optional?: React.ReactNode;
                        } = {};
                        if (options.isStepSkipped(step.index)) {
                            stepProps.completed = false;
                        }
                        return (
                            <Step key={step.label} {...stepProps}>
                                <StepLabel {...labelProps} style={{wordBreak: "break-word"}}>
                                    <FormattedMessage id={step.label} defaultMessage="???"/>
                                </StepLabel>
                            </Step>
                        );
                    })}
                </Stepper>
            }
            <div className="col-12 my-4 justify-content-center align-items-center">
                {options.steps[options.activeStep].content}

                {options.activeStep !== options.steps.length - 1 &&
                    <Box sx={{display: 'flex', flexDirection: 'row', pt: 2}}>
                        <Button
                            color="primary"
                            disabled={options.activeStep === 0}
                            onClick={options.handleBack}
                            sx={{mr: 1}}>
                            <FormattedMessage id="button.back"
                                              defaultMessage="GERİ"
                                              description="Back button"
                            />
                        </Button>
                        <Box sx={{flex: '1 1 auto'}}/>
                        {options.activeStep === options.steps.length - 2 ?
                            <Button
                                onClick={options.handleSubmit}
                                disabled={!(options.steps[options.activeStep].content.props.customerInfo.isPolicyChecked)}>
                                <FormattedMessage id="button.complete"
                                                  defaultMessage="TAMAMLA"
                                                  description="Complete button"
                                />
                            </Button> :
                            <Button
                                onClick={() => {
                                    options.handleNext()
                                    options.steps[options.activeStep].content.props.customerInfo.isValid = false
                                }}
                                disabled={!(options.steps[options.activeStep].content.props.customerInfo.isValid)}>
                                <FormattedMessage id="button.forward"
                                                  defaultMessage="İLERİ"
                                                  description="Forward button"
                                />
                            </Button>}
                    </Box>
                }

            </div>
        </>
    );
}