import React, { useEffect, useState } from 'react';
import { validate } from "../../helpers/global.helper";
import { ICustomerInfo } from "../../models/ICustomerInfo";
import { FormattedMessage, useIntl } from "react-intl";

const CustomerInfoSection = (options: { customerInfo: ICustomerInfo, setCustomerInfo: any }) => {
    useEffect(() => {
        options.setCustomerInfo((pci: any) => {
            pci.isValid = CheckValidation(pci)

            return JSON.parse(JSON.stringify(pci))
        })
    }, []);

    const [validData, setValidData] = useState<any>({});

    function CheckValidation(pci: any) {

        var validObject: any = {};

        validObject["name"] = pci.name == "" ? -1 : validate(pci.name, { minLength: 2, type: "text" }) ? 1 : 0;
        validObject["surname"] = pci.surname == "" ? -1 : validate(pci.surname, { minLength: 2, type: "text" }) ? 1 : 0;
        validObject["email"] = pci.email == "" ? -1 : validate(pci.email, { minLength: 6, type: "email" }) ? 1 : 0;
        validObject["phoneNumber"] = pci.phoneNumber == "" ? -1 : validate(pci.phoneNumber, { minLength: 8, type: "text" }) ? 1 : 0;
        validObject["zipCode"] = pci.zipCode == "" ? -1 : validate(pci.zipCode, { minLength: 4, type: "text" }) ? 1 : 0;
        validObject["country"] = pci.country == "" ? -1 : validate(pci.country, { minLength: 2, type: "text" }) ? 1 : 0;
        validObject["city"] = pci.city == "" ? -1 : validate(pci.city, { minLength: 2, type: "text" }) ? 1 : 0;
        validObject["birthday"] = pci.birthday == "" ? -1 : validate(pci.birthday, { minLength: 6, type: "birthday" }) ? 1 : 0;

        setValidData(validObject)

        var response = true;
        Object.keys(validObject).map(it => {
            console.log()
            if (!validObject[it]) {
                response = false;
            }
        })

        console.log(response)

        return response;
    }

    const lang = useIntl()

    return (
        <div>
            <h2>
                <FormattedMessage id={"customer.info"} defaultMessage="???" />
            </h2>
            <hr />
            <div className="my-4">
                <div className="mb-4">
                    <div className="form-check">
                        <input className="form-check-input"

                            type="radio"
                            name="genderRadio"
                            id="genderMale"
                            checked={options.customerInfo.gender}
                            onChange={() => {
                                options.setCustomerInfo((pci: ICustomerInfo) => {
                                    pci.gender = true
                                    return JSON.parse(JSON.stringify(pci))
                                })
                            }}
                        />
                        <label className="form-check-label" htmlFor="genderMale">
                            <FormattedMessage id={"gender.male"} defaultMessage="???" />
                        </label>

                    </div>
                    <div className="form-check">
                        <input className="form-check-input"
                            type="radio"
                            name="genderRadio"
                            id="genderFemale"
                            checked={!options.customerInfo.gender}
                            onChange={() => {
                                options.setCustomerInfo((pci: ICustomerInfo) => {
                                    pci.gender = false
                                    return JSON.parse(JSON.stringify(pci))
                                })
                            }}
                        />
                        <label className="form-check-label" htmlFor="genderFemale">
                            <FormattedMessage id={"gender.female"} defaultMessage="???" />
                        </label>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-md-6">
                        <label htmlFor="name" className="form-label">
                            <FormattedMessage id={"name"} defaultMessage="???" />
                        </label>
                        <input type="text"
                            className="form-control"
                            id="name"
                            style={{ borderColor: !(validData?.name == 0) ? 'gray' : 'red' }}
                            placeholder={lang.formatMessage({ id: "placeholder.name" })}
                            value={options.customerInfo.name}
                            onChange={(event) => {
                                options.setCustomerInfo((pci: any) => {
                                    pci.name = event.target.value
                                    pci.isValid = CheckValidation(pci);
                                    return JSON.parse(JSON.stringify(pci))
                                })
                            }}
                            required />
                        <p className="validation-text">
                            <FormattedMessage id={"validation.name"} defaultMessage="???" />
                        </p>
                    </div>
                    <div className="col-12 col-md-6">
                        <label htmlFor="surname" className="form-label">
                            <FormattedMessage id={"surname"} defaultMessage="???" />
                        </label>
                        <input type="text"
                            className="form-control"
                            id="surname"
                            style={{ borderColor: !(validData?.surname == 0) ? 'gray' : 'red' }}
                            placeholder={lang.formatMessage({ id: "placeholder.surname" })}
                            value={options.customerInfo.surname}
                            onChange={(event) => {
                                options.setCustomerInfo((pci: any) => {
                                    pci.surname = event.target.value
                                    pci.isValid = CheckValidation(pci);
                                    return JSON.parse(JSON.stringify(pci))
                                })
                            }}
                            required />
                        <p className="validation-text">
                            <FormattedMessage id={"validation.surname"} defaultMessage="???" />
                        </p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-md-6">
                        <label htmlFor="zipCode" className="form-label">
                            <FormattedMessage id={"zipcode"} defaultMessage="???" />
                        </label>
                        <input type="text"
                            className="form-control"
                            id="zipCode"
                            style={{ borderColor: !(validData?.zipCode == 0) ? 'gray' : 'red' }}
                            placeholder={lang.formatMessage({ id: "placeholder.zipcode" })}
                            value={options.customerInfo.zipCode}
                            onChange={(event) => {
                                options.setCustomerInfo((pci: ICustomerInfo) => {
                                    pci.zipCode = event.target.value
                                    pci.isValid = CheckValidation(pci);
                                    return JSON.parse(JSON.stringify(pci))
                                })
                            }}
                            required />
                        <p className="validation-text">
                            <FormattedMessage id={"validation.zipcode"} defaultMessage="???" />
                        </p>
                    </div>
                    <div className="col-12 col-md-6">
                        <label htmlFor="city" className="form-label">
                            <FormattedMessage id={"city"} defaultMessage="???" />
                        </label>
                        <input type="text"
                            className="form-control"
                            id="city"
                            style={{ borderColor: !(validData?.city == 0) ? 'gray' : 'red' }}
                            placeholder={lang.formatMessage({ id: "placeholder.city" })}
                            value={options.customerInfo.city}
                            onChange={(event) => {
                                options.setCustomerInfo((pci: ICustomerInfo) => {
                                    pci.city = event.target.value
                                    pci.isValid = CheckValidation(pci);
                                    return JSON.parse(JSON.stringify(pci))
                                })
                            }}
                            required />
                        <p className="validation-text">
                            <FormattedMessage id={"validation.city"} defaultMessage="???" />
                        </p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-md-6">
                        <label htmlFor="state" className="form-label">
                            <FormattedMessage id={"state"} defaultMessage="???" />
                        </label>
                        <input type="text"
                            className="form-control"
                            id="state"

                            placeholder={lang.formatMessage({ id: "placeholder.state" })}
                            value={options.customerInfo.state}
                            onChange={(event) => {
                                options.setCustomerInfo((pci: ICustomerInfo) => {
                                    pci.state = event.target.value
                                    pci.isValid = CheckValidation(pci);
                                    return JSON.parse(JSON.stringify(pci))
                                })
                            }}
                            required />
                        <p className="validation-text">
                            <FormattedMessage id={"validation.state"} defaultMessage="???" />
                        </p>
                    </div>
                    <div className="col-12 col-md-6">
                        <label htmlFor="country" className="form-label">
                            <FormattedMessage id={"country"} defaultMessage="???" />
                        </label>
                        <input type="text"
                            className="form-control"
                            id="country"
                            style={{ borderColor: !(validData?.country == 0) ? 'gray' : 'red' }}
                            placeholder={lang.formatMessage({ id: "placeholder.country" })}
                            value={options.customerInfo.country}
                            onChange={(event) => {
                                options.setCustomerInfo((pci: ICustomerInfo) => {
                                    pci.country = event.target.value
                                    pci.isValid = CheckValidation(pci);
                                    return JSON.parse(JSON.stringify(pci))
                                })
                            }}
                            required />
                        <p className="validation-text">
                            <FormattedMessage id={"validation.country"} defaultMessage="???" />
                        </p>
                    </div>
                </div>
                <div>
                    <label htmlFor="birthday" className="form-label">
                        <FormattedMessage id={"birthday"} defaultMessage="???" />
                    </label>
                    <input type="date"
                        className="form-control"
                        id="birthday"
                        style={{ borderColor: !(validData?.birthday == 0) ? 'gray' : 'red' }}
                        placeholder={lang.formatMessage({ id: "placeholder.birthday" })}
                        value={options.customerInfo.birthday}
                        onChange={(event) => {
                            options.setCustomerInfo((pci: any) => {
                                pci.birthday = event.target.value
                                pci.isValid = CheckValidation(pci);
                                return JSON.parse(JSON.stringify(pci))
                            })
                        }}
                        required />
                    <p className="validation-text">
                        <FormattedMessage id={"validation.birthday"} defaultMessage="???" />
                    </p>
                </div>
                <div>
                    <label htmlFor="emailAddress" className="form-label">
                        <FormattedMessage id={"email"} defaultMessage="???" />
                    </label>
                    <input type="email"
                        className="form-control"
                        id="emailAddress"
                        style={{ borderColor: !(validData?.email == 0) ? 'gray' : 'red' }}
                        placeholder={lang.formatMessage({ id: "placeholder.email" })}
                        value={options.customerInfo.email}
                        onChange={(event) => {
                            options.setCustomerInfo((pci: any) => {
                                pci.email = event.target.value
                                pci.isValid = CheckValidation(pci);
                                return JSON.parse(JSON.stringify(pci))
                            })
                        }}
                        required />
                    <p className="validation-text">
                        <FormattedMessage id={"validation.email"} defaultMessage="???" />
                    </p>
                </div>
                <div>
                    <label htmlFor="phoneNumber" className="form-label">
                        <FormattedMessage id={"phone.number"} defaultMessage="???" />
                    </label>
                    <input type="text"
                        className="form-control"
                        id="phoneNumber"
                        placeholder={lang.formatMessage({ id: "placeholder.phone.number" })}
                        value={options.customerInfo.phoneNumber}
                        style={{ borderColor: !(validData?.phoneNumber == 0) ? 'gray' : 'red' }}
                        onChange={(event) => {
                            options.setCustomerInfo((pci: any) => {
                                pci.phoneNumber = event.target.value
                                pci.isValid = CheckValidation(pci);
                                return JSON.parse(JSON.stringify(pci))
                            })
                        }}
                        required />
                    <p className="validation-text">
                        <FormattedMessage id={"validation.phone.number"} defaultMessage="???" />
                    </p>
                </div>
            </div>
        </div>
    )
};

export default CustomerInfoSection;