import React from 'react';
import {isNotNullOrEmpty} from "../../helpers/global.helper";
import {FormattedMessage} from "react-intl";

const ReceiptPhotoInputSection = (options: { customerInfo: any, setCustomerInfo: any }) => {
        return (
            <div>
                <h2>
                    <FormattedMessage id={"receipt.info"} defaultMessage="???"/>
                </h2>
                <hr/>
                <div className="my-4">
                    <div className="mb-4">
                        <label htmlFor="photoFiles" className="form-label">
                            <FormattedMessage id={"upload.photos"} defaultMessage="???"/>
                        </label>
                        <input
                            className="form-control"
                            type="file"
                            id="photoFiles"
                            accept="image/*"
                            name="photos"
                            multiple
                            required
                            onChange={(event) => {
                                if (event.target.files !== null) {
                                    const chosenFiles = Array.prototype.slice.call(event.target.files)
                                    const photos = chosenFiles.map(f => URL.createObjectURL(f))
                                    options.setCustomerInfo((pci: any) => {
                                        const clone = JSON.parse(JSON.stringify(pci))
                                        clone.photos = photos
                                        clone.isValid = isNotNullOrEmpty(clone.photos);
                                        clone.photosFormData = event.target.files
                                        console.log(clone.photosFormData)
                                        console.log(clone)
                                        return clone
                                    })
                                }
                            }}
                        />
                    </div>
                </div>
            </div>
        );
    }
;

export default ReceiptPhotoInputSection;