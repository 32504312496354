import React from 'react';
import {Check} from "@mui/icons-material";
import {FormattedMessage} from "react-intl";

const SuccessSection = () => {
    return (
        <div className="d-flex flex-column justify-content-between align-items-center text-center my-auto">
            <Check className="check-icon mb-1rem"/>
            <div className="my-4">
                <h1>
                    <FormattedMessage id={"participation.title"} defaultMessage="???"/>
                </h1>
                <p className="my-4">
                    <FormattedMessage id={"participation.text"} defaultMessage="???"/>
                </p>
            </div>

        </div>
    );
};

export default SuccessSection;