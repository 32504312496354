import React, {useEffect} from 'react';
import {ICustomerInfo} from "../../models/ICustomerInfo";
import {FormattedMessage} from "react-intl";

const Welcome = (options: { customerInfo: ICustomerInfo, setCustomerInfo: any }) => {


    useEffect(() => {
        options.setCustomerInfo((pci: any) => {
            pci.isValid = true;
            return JSON.parse(JSON.stringify(pci))
        })
    }, [])
    return (
        <div className='text-center row'>
            <div className='col-md-3'>
                <img alt="warning" style={{maxHeight: 200}} src='https://185.169.54.83:3187/photos/warning.png'/>

            </div>
            <div className='col-md-9 d-flex flex-column justify-content-center'>
                <h5 className='mt-2'>
                    <FormattedMessage id="welcome.title"
                                      defaultMessage="???"
                    />
                </h5>
                <div style={{fontSize: 14}}>
                    <FormattedMessage id="welcome.text"
                                      defaultMessage="???"
                    />
                </div>
            </div>


        </div>
    )
};

export default Welcome;