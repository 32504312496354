import React, {useState} from 'react';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import './app.scss'
import CustomStepper from "./components/CustomStepper";
import {IStep} from "./models/IStep";
import CustomerInfoSection from "./components/sections/CustomerInfoSection";
import ReceiptPhotoInputSection from "./components/sections/ReceiptPhotoInputSection";
import VerifyInfoSection from "./components/sections/VerifyInfoSection";
import SuccessSection from "./components/sections/SuccessSection";
import {ICustomerInfo} from "./models/ICustomerInfo";
import axios from "axios";
import ErrorComponent from "./components/ErrorComponent";
import {toDate} from "./helpers/global.helper";
import Welcome from './components/sections/Welcome';
import {IntlProvider} from "react-intl";
import messages_tr from "./l18n/tr.json";
import messages_de from "./l18n/de.json";
import messages_fr from "./l18n/fr.json";
import {LOCALES} from "./l18n/locales";
import LanguageSwitcher from "./components/LanguageSwitcher";

const messages = {
    [LOCALES.TURKISH]: messages_tr,
    [LOCALES.GERMAN]: messages_de,
    [LOCALES.FRENCH]: messages_fr
};

function App() {
    const [customerInfo, setCustomerInfo] = useState<ICustomerInfo>({
        name: "",
        surname: "",
        email: "",
        phoneNumber: "",
        zipCode: "",
        country: "",
        state: "",
        city: "",
        gender: true,
        birthday: "",
        isValid: false
    })

    const [activeStep, setActiveStep] = useState(0);
    const [skipped, setSkipped] = useState(new Set<number>());
    const [hasError, setHasError] = useState(false)
    const savedLocale = localStorage.getItem("locale");
    const [currentLanguage, setCurrentLanguage] = useState(savedLocale || LOCALES.GERMAN)

    const steps: IStep[] = [
        {
            index: 0,
            label: "hello",
            content: <Welcome customerInfo={customerInfo} setCustomerInfo={setCustomerInfo}/>,
        },
        {
            index: 1,
            label: "customer.info",
            content: <CustomerInfoSection customerInfo={customerInfo} setCustomerInfo={setCustomerInfo}/>,
        },
        {
            index: 2,
            label: "receipt.info",
            content: <ReceiptPhotoInputSection customerInfo={customerInfo} setCustomerInfo={setCustomerInfo}/>,
        },
        {
            index: 3,
            label: "verify.info",
            content: <VerifyInfoSection customerInfo={customerInfo} setCustomerInfo={setCustomerInfo}/>,
        },
        {
            index: 4,
            label: "success",
            content: <SuccessSection/>,
        },
    ]

    const isStepSkipped = (step: number) => {
        return skipped.has(step);
    };

    const handleNext = () => {
        let newSkipped = skipped;
        if (isStepSkipped(activeStep)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(activeStep);
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleSubmit = (event: any) => {
        const formData = new FormData()
        if (customerInfo.photosFormData && customerInfo.photosFormData.length > 0) {
            for (let i = 0; i < customerInfo.photosFormData.length; i++) {
                formData.append("photos", customerInfo.photosFormData[i])
            }
        }
        formData.append("name", customerInfo.name ?? "")
        formData.append("surname", customerInfo.surname ?? "")
        formData.append("email", customerInfo.email ?? "")
        formData.append("phoneNumber", customerInfo.phoneNumber ?? "")
        formData.append("zipCode", customerInfo.zipCode ?? "")
        formData.append("country", customerInfo.country ?? "")
        formData.append("state", customerInfo.state ?? "")
        formData.append("city", customerInfo.city ?? "")
        formData.append("gender", customerInfo.gender === true ? "male" : "female")
        formData.append("birthday", toDate(customerInfo.birthday ?? "") ?? "")

        const config = {
            baseURL: `https://185.169.54.83:3187/`,
            // baseURL: `http://192.168.1.100:7000/`,
            headers: {
                'content-type': 'multipart/form-data',
            },
        }

        // POST data

        axios.post('giveaway/participate', formData, config).then(r => {
            if (r.status === 201) {
                setActiveStep(pas => pas + 1)
            }
        }).catch(() => {
            setActiveStep(0)
            setHasError(true)
            return
            // if (e?.response?.data?.message) {
            //     const message: string = e.response.data.message.toString()
            //     if (message.includes("exists")) {
            //         if (message.includes("phoneNumber"))
            //             setErrorText("Girmiş olduğunuz telefon numarası ile katılım yapılmıştır. Farklı bir telefon numarası deneyiniz.")
            //         else if (message.includes("email"))
            //             setErrorText("Girmiş olduğunuz e-posta adresi ile katılım yapılmıştır. Farklı bir e-posta adresi deneyiniz.")
            //     }
            //     return
            // }

            // setErrorText("Bilinmeyen bir hata oluştu. Lütfen tekrar deneyiniz.")
        })

        event.preventDefault();
    }

    return (
        <IntlProvider locale={currentLanguage} messages={messages[currentLanguage]}>
            <div className="min-vh-100 gradient-background">
                <div className="main custom-container min-vh-100 d-flex flex-column justify-content-center">
                    <div className="custom-card">
                        <div className="w-100 d-flex justify-content-end">
                            <LanguageSwitcher locale={LOCALES.GERMAN} onClick={setCurrentLanguage}/>
                            <LanguageSwitcher locale={LOCALES.TURKISH} onClick={setCurrentLanguage}/>
                            <LanguageSwitcher locale={LOCALES.FRENCH} onClick={setCurrentLanguage}/>
                        </div>
                        <div className="d-flex justify-content-center align-items-center mb-4">
                            <img alt="logo"
                                 className="img-fluid"
                                 style={{width: "15rem"}}
                                 src={'https://www.melis.com.tr/vendor/img/logo.svg'}/>
                        </div>
                        {
                            hasError ?
                                <ErrorComponent setHasError={setHasError}/> :
                                <form>
                                    {
                                        steps.length > 0 &&
                                        <CustomStepper
                                            steps={steps}
                                            activeStep={activeStep}
                                            isStepSkipped={isStepSkipped}
                                            handleNext={handleNext}
                                            handleBack={handleBack}
                                            handleSubmit={handleSubmit}
                                        />
                                    }
                                </form>
                        }
                    </div>

                </div>
            </div>
        </IntlProvider>
    );
}

export default App;
